import React, { useState, useEffect, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { Card } from 'primereact/card';
import LoginFormData from '../types/LoginFormData';
import { Password } from 'primereact/password';
import { GoogleLogin } from '@react-oauth/google';
import { Toast } from 'primereact/toast';
import { login } from '../api';

interface LoginFormProps {
  formData: LoginFormData;
  setFormData: React.Dispatch<React.SetStateAction<LoginFormData>>;
  setIsLoginVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsForgotPasswordVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginForm = (props: LoginFormProps) => {
  const { email, password } = props.formData;
  const { formData, setFormData, setIsLoginVisible, setIsForgotPasswordVisible } = props;
  const [showDialog, setShowDialog] = useState(false);
  const toast = useRef<any>(null);

  return <div>

    <Toast ref={toast} />

    <Dialog onHide={() => setShowDialog(false)}>
      <div>Registration Successful</div>
    </Dialog>

    <GoogleLogin
      onSuccess={credentialResponse => {
        console.log(credentialResponse);
      }}
      onError={() => {
        console.log('Login Failed');
      }}
    />

    <Divider layout="horizontal" className="flex" align="center">
        <div>or</div>
    </Divider>

    <Card>
      <span className="p-float-label">
        <InputText
          id="email"
          value={email}
          onChange={(e) => setFormData({...formData, email: e.target.value})}
        />
        <label htmlFor="email">Email</label>
      </span>
      <span className="mt-8 p-float-label">
        <Password
          feedback={false}
          id="password"
          value={password}
          onChange={(e) => setFormData({...formData, password: e.target.value})}
        />
        <label htmlFor="password">Password</label>
      </span>
      <Button
        onClick={() => {
          login(formData)
            .then(res => {
              // console.log('res', res);
              // if (res.status === 200) {
              //   toast.current.show({ severity: 'success', summary: 'Success', detail: 'Login Successful', life: 3000 });
              // } else {
              //   toast.current.show({ severity: 'error', summary: 'Login Failed', detail: `${res.message}`, life: 3000 });
              // }
            })
            .catch(err => {
              toast.current.show({ severity: 'error', summary: 'Login Failed', detail: `${err}`, life: 3000 });
            });
        }}
        label='Login with Email'
        className="mt-4"
      />
      <div
        className="underline hover:text-gray-500 cursor-pointer"
        onClick={() => {
          setIsLoginVisible(false);
          setIsForgotPasswordVisible(true);
        }}
      >
        Forgot Password
      </div>
    </Card>

  </div>
}

export default LoginForm;