import LoginFormData from "./types/LoginFormData";

const API_URL = 'http://localhost:3001/api';

export async function login(data: LoginFormData) {
  const { email, password } = data;

  return fetch(`${API_URL}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });
}

export async function signup(data: LoginFormData) {
  const { email } = data;

  return fetch(`${API_URL}/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      password: 'test',
      name: 'douglas',
      role: 'admin',
    }),
  });
}