import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { Card } from 'primereact/card';
import { Password } from 'primereact/password';
import { GoogleLogin } from '@react-oauth/google';

const ForgotPassword = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [email, setEmail] = useState('');

  return <div>

    <Dialog onHide={() => setShowDialog(false)}>
      <div>Reset Password Link Sent</div>
    </Dialog>

    <Card>
      <span className="p-float-label">
        <InputText
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="email">Email</label>
      </span>
      <Button label='Reset Password' className="mt-4" onClick={() => {
        setShowDialog(true);
      }}/>
    </Card>

  </div>
}

export default ForgotPassword;