import Settings from "./types/Settings";

export const constants = {
  ASPECT_RATIO: window.screen.width / window.screen.height,
  DEFAULT_USING_SCREEN: true,
  DEFAULT_USING_WEBCAM: true,
  DEFAULT_SHARING_DURATION: 25 * 60 * 1000, // 25 minutes
  DEFAULT_SHORT_BREAK_DURATION: 5 * 60 * 1000, // 5 minutes
  DEFAULT_LONG_BREAK_DURATION: 15 * 60 * 1000, // 15 minutes
};

export const DEFAULT_SETTINGS: Settings = {
  usingScreen: constants.DEFAULT_USING_SCREEN,
  usingWebcam: constants.DEFAULT_USING_WEBCAM,
  sharingDuration: constants.DEFAULT_SHARING_DURATION,
  shortBreakDuration: constants.DEFAULT_SHORT_BREAK_DURATION,
  longBreakDuration: constants.DEFAULT_LONG_BREAK_DURATION,
}