import React, { useState, useEffect, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { Card } from 'primereact/card';
import RegisterFormData from '../types/RegisterFormData';
import { Password } from 'primereact/password';
import { GoogleLogin } from '@react-oauth/google';
import { signup } from '../api';
import { Toast } from 'primereact/toast';



interface RegisterFormProps {
  formData: RegisterFormData;
  setFormData: React.Dispatch<React.SetStateAction<RegisterFormData>>;
}


const RegisterForm = (props: RegisterFormProps) => {
  const { formData, setFormData } = props;
  const { email, password } = formData;
  const [showDialog, setShowDialog] = useState(false);
  const toast = useRef<any>(null);

  return <div>
    <Toast ref={toast} />

    <Dialog onHide={() => setShowDialog(false)}>
      <div>Registration Successful</div>
    </Dialog>

    <GoogleLogin
      onSuccess={credentialResponse => {
        console.log(credentialResponse);
      }}
      onError={() => {
        console.log('Sign up Failed');
      }}
    />

    <Divider layout="horizontal" className="flex" align="center">
        <div>or</div>
    </Divider>

    <Card>
      <span className="p-float-label">
        <InputText
          id="email"
          value={email}
          onChange={(e) => setFormData({...formData, email: e.target.value})}
        />
        <label htmlFor="email">Email</label>
      </span>
      <Button
        onClick={async () => {
          try {
            const res = await signup(formData);
            const data = await res.json();

            if (res.status !== 200) {
              toast.current.show({severity: 'error', summary: 'Registration Failed', detail: `${data.error}`});
            }
  
            if (res.status === 200) {
              toast.current.show({severity: 'success', summary: 'Registration Successful', detail: `Welcome ${formData.email}`});
            }
          } catch (err) {
            toast.current.show({severity: 'error', summary: 'Registration Failed', detail: `${err}`});
          }
        }}
        label='Sign up with Email'
        className="mt-4"
      />
    </Card>

  </div>
}

export default RegisterForm;