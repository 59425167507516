const VARIANTS_MAP: Record<string, string> = {
  'orange': 'text-white bg-orange-500 hover:bg-orange-600',
  'red': 'text-white bg-red-500 hover:bg-red-600',
  'blue': 'text-white bg-blue-500 hover:bg-blue-600',
  'green': 'text-white bg-green-500 hover:bg-green-600',
  'light-gray': 'text-black bg-gray-200 hover:bg-gray-300',
  'dark-gray': 'text-white bg-gray-800 hover:bg-gray-900',
}

export function Button({ children, variant, className, ...props }: any) {
  return (
    <button
      className={`px-8 py-4 rounded ${VARIANTS_MAP[variant]} ${className}`}
      {...props}
      >
      {children}
    </button>
  );
}