import "primereact/resources/themes/tailwind-light/theme.css";     //theme
import "primereact/resources/primereact.min.css";                     //core css
import "primeicons/primeicons.css";                                   //icons

import { PrimeReactProvider } from 'primereact/api';
import { useEffect, useRef, useReducer, useState } from 'react';
import './App.css';
import Countdown, { formatTimeDelta } from 'react-countdown';
import useSound from 'use-sound';
import Cookies from 'js-cookie';
import kitchenTimerSound from './kitchen-timer.mp3';
import clickSound from './click.mp3';
import startSound from './start.mp3';
import { Button } from './components/Button';
import { Button as PrimeReactButton } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Divider } from "primereact/divider";
import { Dialog } from "primereact/dialog";
import { DEFAULT_SETTINGS } from './constants';
import { humanFileSize } from './util';
import Modal from 'react-modal';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import Settings from './types/Settings';
import Draggable from "react-draggable";
import { GoogleOAuthProvider } from '@react-oauth/google';
import config from "./config/config";
import { Password } from 'primereact/password';
import { InputText } from "primereact/inputtext";
import LoginFormData from "./types/LoginFormData";
import RegisterFormData from "./types/RegisterFormData";
import LoginForm from "./components/LoginForm";
import RegisterForm from "./components/RegisterForm";
import ForgotPassword from "./components/ForgotPassword";

const renderer = (timeDelta: any) => {
  const { hours, minutes, seconds } = formatTimeDelta(timeDelta, {daysInHours: true});

  if (hours !== '00') {
    return <div id="timer" className="text-center font-bold">{hours}:{minutes}:{seconds}</div>
  } else {
    return <div id="timer" className="text-center font-bold">{minutes}:{seconds}</div>
  }
};

enum TimerType {
  POMODORO,
  SHORT_BREAK,
  LONG_BREAK,
}

interface State {
  isSharingStarted: boolean;
  isShortBreakStarted: boolean;
  isLongBreakStarted: boolean;
  isSharingPaused: boolean;
  isShortBreakPaused: boolean;
  isLongBreakPaused: boolean;
  isSharingCompleted: boolean;
  isShortBreakCompleted: boolean;
  isLongBreakCompleted: boolean;
  timerType: TimerType;
  longBreakInterval: number;
  numPomodoros: number;
  countdownDate: Date;
  screenStream?: MediaStream;
  screenRecorder?: MediaRecorder;
  webcamStream?: MediaStream;
  webcamRecorder?: MediaRecorder;
  isSettingsVisible: boolean;
  settings: Settings;
  newSettings: Settings;
  loginFormData: LoginFormData;
  registerFormData: RegisterFormData;
}

function reducer(prevState: State, update: Partial<State> | ((prevState: State) => Partial<State>)) {
  if (typeof update === 'function') {
    return { ...prevState, ...update(prevState) };
  }
  return { ...prevState, ...update };
}

console.log('cookies', Cookies.get())

const initialSettings = {
  usingScreen: Cookies.get('usingScreen') !== undefined ? Cookies.get('usingScreen') === 'true' : DEFAULT_SETTINGS.usingScreen,
  usingWebcam: Cookies.get('usingWebcam') !== undefined ? Cookies.get('usingWebcam') === 'true' : DEFAULT_SETTINGS.usingWebcam,
  sharingDuration: Number(Cookies.get('sharingDuration')) || DEFAULT_SETTINGS.sharingDuration,
  shortBreakDuration: Number(Cookies.get('shortBreakDuration')) || DEFAULT_SETTINGS.shortBreakDuration,
  longBreakDuration: Number(Cookies.get('longBreakDuration')) || DEFAULT_SETTINGS.longBreakDuration,
}

const INITIAL_STATE = {
  isSharingStarted: false,
  isShortBreakStarted: false,
  isLongBreakStarted: false,
  isSharingPaused: false,
  isShortBreakPaused: false,
  isLongBreakPaused: false,
  isSharingCompleted: false,
  isShortBreakCompleted: false,
  isLongBreakCompleted: false,
  timerType: TimerType.POMODORO,
  longBreakInterval: 4,
  numPomodoros: 0,
  countdownDate: new Date(Date.now() + (25 * 60 * 1000)),
  screenStream: undefined,
  screenRecorder: undefined,
  webcamStream: undefined,
  webcamRecorder: undefined,
  isSettingsVisible: false,
  settings: initialSettings,
  newSettings: initialSettings,
  loginFormData: {
    email: '',
    password: '',
  },
  registerFormData: {
    email: '',
    password: '',
    confirmPassword: '',
  },
};

let chunks: any[] = [];

function App() {

  const [state, setState] = useReducer(
    reducer,
    INITIAL_STATE,
  );
  const {
    isSharingStarted,
    isShortBreakStarted,
    isLongBreakStarted,
    isSharingPaused,
    isShortBreakPaused,
    isLongBreakPaused,
    isSharingCompleted,
    isShortBreakCompleted,
    isLongBreakCompleted,
    timerType,
    longBreakInterval,
    numPomodoros,
    countdownDate,
    screenStream,
    screenRecorder,
    webcamStream,
    webcamRecorder,
    isSettingsVisible,
    settings: {
      usingScreen,
      usingWebcam,
      sharingDuration,
      shortBreakDuration,
      longBreakDuration,
    },
    newSettings,
    loginFormData: {
      email: loginEmail,
      password: loginPassword,
    },
    registerFormData: {
      email: registerEmail,
      password: registerPassword,
      confirmPassword: registerConfirmPassword,
    },
  } = state;
  const toast = useRef<any>(null);
  const [videoBlob, setVideoBlob] = useState<Blob | undefined>();
  const [loginFormData, setLoginFormData] = useState<LoginFormData>({
    email: '',
    password: '',
  });
  const [registerFormData, setRegisterFormData] = useState<RegisterFormData>({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [isLoginVisible, setIsLoginVisible] = useState<boolean>(false);
  const [isRegisterVisible, setIsRegisterVisible] = useState<boolean>(false);
  const [isForgotPasswordVisible, setIsForgotPasswordVisible] = useState<boolean>(false);

  const isStarted = isSharingStarted || isShortBreakStarted || isLongBreakStarted;
  const isBreakStarted = isShortBreakStarted || isLongBreakStarted;
  const isPaused = isSharingPaused || isShortBreakPaused || isLongBreakPaused;
  const isBreakCompleted = isShortBreakCompleted || isLongBreakCompleted;
  const isAuthVisible = isLoginVisible || isRegisterVisible;
  const [playCountdownCompleteSound] = useSound(kitchenTimerSound);
  const [playClickSound] = useSound(clickSound);
  const [playStartSound] = useSound(startSound);

  const screenVideo = useRef<any>();
  const webcamVideo = useRef<any>();
  const countdown = useRef<any>();

  useEffect(() => {

  })

  useEffect(() => {
    console.log(state)
  }, [state])

  useEffect(() => {
    let countdownDate;
    if (timerType === TimerType.POMODORO) {
      countdownDate = new Date(Date.now() + sharingDuration);
    } else if (timerType === TimerType.SHORT_BREAK) {
      countdownDate = new Date(Date.now() + shortBreakDuration);
    } else {
      countdownDate = new Date(Date.now() + longBreakDuration);
    }
    setState({
      ...state,
      isSharingStarted: false,
      isShortBreakStarted: false,
      isLongBreakStarted: false,
      isSharingPaused: false,
      isShortBreakPaused: false,
      isLongBreakPaused: false,
      isSharingCompleted: false,
      isShortBreakCompleted: false,
      isLongBreakCompleted: false,
      countdownDate,
      screenStream: undefined,
      screenRecorder: undefined,
      webcamStream: undefined,
      webcamRecorder: undefined,
    })
  }, [timerType, sharingDuration, shortBreakDuration, longBreakDuration]);

  useEffect(() => {
    if (usingScreen && timerType === TimerType.POMODORO && isSharingStarted && !isSharingPaused && !isSharingCompleted && !screenStream) {
      requestScreenStream();
    }
  }, [timerType, isSharingStarted, isSharingPaused, screenStream]);

  useEffect(() => {
    if (usingWebcam && timerType === TimerType.POMODORO && isSharingStarted && !isSharingPaused && !isSharingCompleted && !webcamStream) {
      requestWebcamStream();
    }
  }, [timerType, isSharingStarted, isSharingPaused, webcamStream]);

  useEffect(() => {
    if (timerType === TimerType.POMODORO && isSharingStarted && !isSharingPaused && screenStream && screenVideo.current) {

      if (!screenVideo.current.srcObject) {
        screenVideo.current.srcObject = screenStream
      } else {
        screenVideo.current.srcObject.getTracks().forEach((track: any) => track.enabled = true);
        screenRecorder?.resume()
      }

      screenVideo.current.onloadedmetadata = function(e: any) {
        screenVideo.current.play();
      };

      countdown.current.start();
    }

    if (timerType === TimerType.POMODORO && isSharingStarted && !isSharingPaused && webcamStream && webcamVideo.current) {
        
      if (!webcamVideo.current.srcObject) {
        webcamVideo.current.srcObject = webcamStream
      } else {
        webcamVideo.current.srcObject.getTracks().forEach((track: any) => track.enabled = true);
        webcamRecorder?.resume()
      }

      webcamVideo.current.onloadedmetadata = function(e: any) {
        webcamVideo.current.play();
      };

      countdown.current.start();
    }


    if (timerType !== TimerType.POMODORO && isBreakStarted && !isSharingPaused) {
      countdown.current.start();
    }

    if (timerType === TimerType.POMODORO && isSharingStarted && isPaused && screenStream && screenVideo.current) {
      screenVideo.current.srcObject.getTracks().forEach((track: any) => track.enabled = false);
      screenRecorder?.pause()
      countdown.current.pause()
    }

    if (timerType === TimerType.POMODORO && isSharingStarted && isPaused && webcamStream && webcamVideo.current) {
      webcamVideo.current.srcObject.getTracks().forEach((track: any) => track.enabled = false);
      webcamRecorder?.pause()
      countdown.current.pause()
    }

    if (timerType !== TimerType.POMODORO && isBreakStarted && isPaused) {
      countdown.current.pause();
    }

    if (isSharingCompleted || timerType !== TimerType.POMODORO) {
      if (screenStream) {
        screenStream.getTracks().forEach((track: any) => track.stop());
      }
      if (webcamStream) {
        webcamStream.getTracks().forEach((track: any) => track.stop());
      }
    }

    if (isSharingCompleted) {
      const numberOfPomodoros = numPomodoros + 1;
      screenVideo.current.srcObject = null;
      if (numberOfPomodoros < longBreakInterval) {
        setState({
          ...state,
          timerType: TimerType.SHORT_BREAK,
          numPomodoros: numberOfPomodoros,
          isSharingStarted: false,
          isSharingCompleted: false,
        });
      } else {
        setState({
          ...state,
          timerType: TimerType.LONG_BREAK,
          numPomodoros: 0,
          isSharingStarted: false,
          isSharingCompleted: false,
        });
      }
    } else if (isBreakCompleted) {
      setState({
        ...state,
        timerType: TimerType.POMODORO,
        isShortBreakStarted: false,
        isShortBreakCompleted: false,
        isLongBreakStarted: false,
        isLongBreakCompleted: false,
      });
    }
  }, [
    timerType,
    isSharingStarted,
    isShortBreakStarted,
    isLongBreakStarted,
    isSharingPaused,
    isShortBreakPaused,
    isLongBreakPaused,
    isSharingCompleted,
    isShortBreakCompleted,
    isLongBreakCompleted,
    longBreakInterval,
    numPomodoros,
    screenStream,
    screenRecorder,
    webcamStream,
    webcamRecorder,
  ]);

  const requestScreenStream = async () => {
    console.log('requestScreenStream')

    try {
      const options: any = {
        video: {
          displaySurface: 'monitor',
        },
        preferCurrentTab: false,
      };

      const screenStream = await navigator.mediaDevices
        .getDisplayMedia(options)
        .catch((error) => {
          alert("Error: Please grant permission to share your screen.");
          throw error;
        });
      const screenRecorder = new MediaRecorder(screenStream);

      screenRecorder.addEventListener('dataavailable', (e) => {
        chunks.push(e.data);
      });

      screenRecorder.addEventListener('stop', () => {
        const blob = new Blob(chunks, {
          'type': 'video/webm',
        });
        if (isSharingCompleted) {
          chunks = [];
        }
        setVideoBlob(blob);
      });

      screenRecorder.start();

      screenStream.getTracks()[0].addEventListener('ended', () => {
        console.log('The user has ended sharing the screen');
        setState((prevState) => ({
          ...prevState,
          screenStream: undefined,
          isSharingCompleted: true,
        }));
      });

      setState((prevState: State) => ({
        ...prevState,
        screenStream,
        screenRecorder,
        isSharingStarted: true,
        isSharingPaused: false,
      }));
      playStartSound();

    } catch (error) {
      console.error('Error accessing screen share:', error);
    }
  };

  const requestWebcamStream = async () => {
    console.log('requestWebcamStream')

    try {
      const webcamStream = await navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .catch((error) => {
          alert("Error: Please grant permission to use your webcam.");
          throw error;
        });
      const webcamRecorder = new MediaRecorder(webcamStream);

      webcamRecorder.addEventListener('dataavailable', (e) => {
        chunks.push(e.data);
      });

      webcamRecorder.addEventListener('stop', () => {
        const blob = new Blob(chunks, {
          'type': 'video/webm',
        });
        chunks = [];
        setVideoBlob(blob);
      });

      webcamRecorder.start();

      webcamStream.getTracks()[0].addEventListener('ended', () => {
        console.log('The user has ended sharing the webcam');
        setState((prevState) => ({
          ...prevState,
          webcamStream: undefined,
        }));
      });

      setState((prevState: State) => ({
        ...prevState,
        webcamStream,
        webcamRecorder,
        isSharingStarted: true,
        isSharingPaused: false,
      }));
      playStartSound();

    } catch (error) {
      console.error('Error accessing webcam:', error);
    }
  }

  function handleSettingsChange() {
    Cookies.set('usingScreen', String(newSettings.usingScreen));
    Cookies.set('usingWebcam', String(newSettings.usingWebcam));
    Cookies.set('sharingDuration', String(newSettings.sharingDuration));
    Cookies.set('shortBreakDuration', String(newSettings.shortBreakDuration));
    Cookies.set('longBreakDuration', String(newSettings.longBreakDuration));
    if (JSON.stringify(newSettings) !== JSON.stringify(state.settings)) {
      toast.current.show({severity:'success', summary: 'Settings Saved', detail: 'Settings have been saved.', life: 3000});
    }
    setState({
      ...state,
      settings: newSettings,
      isSettingsVisible: false,
    });
  }


  return (
    <PrimeReactProvider>
      <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
      <div className="App">
        <Toast ref={toast} />

        <header className="App-header min-h-screen p-6">
        
        <div className="flex flex-row justify-between" style={{width: 640}}>
          <span className="text-5xl text-white">Work in Public</span>
          <PrimeReactButton className="p-button-secondary" onClick={() => setState({...state, isSettingsVisible: true})}>
            ⚙️Settings
          </PrimeReactButton>
          <PrimeReactButton className="p-button-secondary" onClick={() => setIsLoginVisible(true)}>
            Login
          </PrimeReactButton>
        </div>
        
        <main className="mt-4 flex flex-col items-center max-w-screen-md">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-center space-x-4 mb-6">
              { timerType === TimerType.POMODORO
                ? <Button variant="orange" onClick={() => setState({...state, timerType: TimerType.POMODORO})}>Pomodoro</Button>
                : <Button variant="light-gray" onClick={() => setState({...state, timerType: TimerType.POMODORO})}>Pomodoro</Button>
              }
              { timerType === TimerType.SHORT_BREAK
                ? <Button variant="orange" onClick={() => setState({...state, timerType: TimerType.SHORT_BREAK})}>
                    <span className="w-5/6">Short Break</span>
                  </Button>
                : <Button variant="light-gray" onClick={() => setState({...state, timerType: TimerType.SHORT_BREAK})}>Short Break</Button>
              }
              { timerType === TimerType.LONG_BREAK
                ? <Button variant="orange" onClick={() => setState({...state, timerType: TimerType.LONG_BREAK})}>Long Break</Button>
                : <Button variant="light-gray" onClick={() => setState({...state, timerType: TimerType.LONG_BREAK})}>Long Break</Button>
              }
            </div>
            { timerType === TimerType.POMODORO &&
              <div className="rounded flex justify-center mb-6 relative bg-black" style={{width: 640, height: 360}}>
                <video
                  className="rounded bg-black w-full"
                  ref={screenVideo}
                />
                { usingWebcam && isSharingStarted && !isPaused && webcamStream &&
                  <Draggable
                    bounds="parent"
                  >
                    <video
                      className="m-0 border-none bg-black w-40 h-40 object-cover absolute bottom-2 left-2"
                      ref={webcamVideo}
                      disablePictureInPicture
                    />
                  </Draggable>
                }
                
                {
                  isSharingStarted && !isPaused && (screenStream || webcamStream) &&
                  <div className="text-white text-right text-2xl text-white-500 absolute bottom-2 right-2 pointer-events-none bg-black rounded">🟢SHARING</div>
                }
                {
                  isSharingStarted && isPaused && (screenStream || webcamStream) &&
                  <div className="text-white text-right text-2xl text-white-500 absolute bottom-2 right-2 pointer-events-none bg-black rounded">⏸️PAUSED</div>
                }
                {
                  !screenStream && !webcamStream &&
                  <div className="text-white text-right text-2xl text-white-500 absolute bottom-2 right-2 pointer-events-none bg-black rounded">⚫OFFLINE</div>
                }
              </div>
            }
            {timerType === TimerType.POMODORO &&
              <Countdown
                date={countdownDate}
                renderer={renderer}
                autoStart={false}
                daysInHours={true}
                ref={countdown}
                onComplete={playCountdownCompleteSound}
              />
            }
            {timerType === TimerType.SHORT_BREAK &&
              <Countdown
                date={countdownDate}
                renderer={renderer}
                autoStart={false}
                daysInHours={true}
                ref={countdown}
                onComplete={playCountdownCompleteSound}
              />
            }
            {timerType === TimerType.LONG_BREAK &&
              <Countdown
                date={countdownDate}
                renderer={renderer}
                autoStart={false}
                daysInHours={true}
                ref={countdown}
                onComplete={playCountdownCompleteSound}
              />
            }
            <div className="flex justify-center space-x-4">
              {timerType === TimerType.POMODORO && (!screenStream || (usingWebcam && !webcamStream)) &&
                <Button
                  variant="green"
                  onClick={() => {
                    setState({
                      ...state,
                      isSharingStarted: true,
                    })
                    playClickSound()
                  }}>
                    Start
                </Button>
              }
              {timerType === TimerType.SHORT_BREAK && !isStarted &&
                <Button
                  variant="green"
                  onClick={() => {
                    setState({
                      ...state,
                      isShortBreakStarted: true,
                    })
                    playClickSound();
                  }}>
                    Start
                </Button>
              }
              {timerType === TimerType.LONG_BREAK && !isStarted &&
                <Button
                  variant="green"
                  onClick={() => {
                    setState({
                      ...state,
                      isLongBreakStarted: true,
                    })
                    playClickSound();
                  }}>
                    Start
                </Button>
              }
              { timerType === TimerType.POMODORO && isSharingStarted && !isSharingPaused && screenStream && (!usingWebcam || webcamStream) &&
                <Button
                  variant="blue"
                  onClick={() => {
                    setState({
                      ...state,
                      isSharingPaused: true,
                    });
                    playClickSound();
                  }}
                >
                  Pause
                </Button>
              }
              { timerType === TimerType.SHORT_BREAK && isShortBreakStarted && !isShortBreakPaused &&
                <Button
                  variant="blue"
                  onClick={() => {
                    setState({
                      ...state,
                      isShortBreakPaused: true,
                    });
                    playClickSound();
                  }}
                >
                  Pause
                </Button>
              }
              { timerType === TimerType.LONG_BREAK && isLongBreakStarted && !isLongBreakPaused &&
                <Button
                  variant="blue"
                  onClick={() => {
                    setState({
                      ...state,
                      isLongBreakPaused: true,
                    });
                    playClickSound();
                  }}
                >
                  Pause
                </Button>
              }
              { timerType === TimerType.POMODORO && isSharingStarted && isSharingPaused && screenStream && (!usingWebcam || webcamStream) &&
                <Button
                  variant="green"
                  onClick={() => {
                    setState({
                      ...state,
                      isSharingPaused: false,
                    });
                    playClickSound();
                  }}
                >
                  Resume
                </Button>
              }
              { timerType === TimerType.SHORT_BREAK && isShortBreakStarted && isShortBreakPaused &&
                <Button
                  variant="green"
                  onClick={() => {
                    setState({
                      ...state,
                      isShortBreakPaused: false,
                    });
                    playClickSound();
                  }}
                >
                  Resume
                </Button>
              }
              { timerType === TimerType.LONG_BREAK && isLongBreakStarted && isLongBreakPaused &&
                <Button
                  variant="green"
                  onClick={() => {
                    setState({
                      ...state,
                      isLongBreakPaused: false,
                    });
                    playClickSound();
                  }}
                >
                  Resume
                </Button>
              }
              { timerType === TimerType.POMODORO && isSharingStarted &&
                <Button
                  variant="red"
                  onClick={() => {
                    setState({
                      ...state,
                      isSharingCompleted: true,
                    });
                    playClickSound();
                  }}
                >
                  End
                </Button>
              }
              { timerType === TimerType.SHORT_BREAK && isShortBreakStarted &&
                <Button
                  variant="red"
                  onClick={() => {
                    setState({
                      ...state,
                      isShortBreakCompleted: true,
                    });
                    playClickSound();
                  }}
                >
                  End
                </Button>
              }
              { timerType === TimerType.LONG_BREAK && isLongBreakStarted &&
                <Button
                  variant="red"
                  onClick={() => {
                    setState({
                      ...state,
                      isLongBreakCompleted: true,
                    });
                    playClickSound();
                  }}
                >
                  End
                </Button>
              }
            </div>
            { timerType === TimerType.POMODORO && isStarted && !isPaused && (!screenStream || (usingWebcam && !webcamStream)) &&
              <div className="text-black">Share your screen to start the timer</div>
            }
          </div>
        </main>
        { videoBlob && 
          <main className="mt-10 flex flex-col items-center max-w-screen-md">
            <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-center space-x-4">
              <Button
                variant="blue"
                onClick={() => {
                  const link = document.createElement('a');
                  link.href = window.URL.createObjectURL(videoBlob);
                  link.download = 'work-in-public';
                  link.click();
                }}
                className="w-full"
              >
                {`Download (${humanFileSize(videoBlob.size)})`}
              </Button>
            </div>
            </div>
          </main>
        }
        </header>

        {isSettingsVisible &&
          <Dialog
            visible={isSettingsVisible}
            dismissableMask
            onHide={handleSettingsChange}
            header='Settings'
            // breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '50vw'}}
          >
            <label htmlFor="using-screen" className="block mt-4">Screen</label>
            <InputSwitch
              inputId="using-screen"
              checked={newSettings.usingScreen}
              onChange={(e) => setState({...state, newSettings: {...newSettings, usingScreen: e.value}})}
            />

            <label htmlFor="using-webcam" className="block mt-4">Webcam</label>
            <InputSwitch
              inputId="using-webcam"
              checked={newSettings.usingWebcam}
              onChange={(e) => setState({...state, newSettings: {...newSettings, usingWebcam: e.value}})}
            />

            <label htmlFor="sharing-duration" className="block mt-4">Pomodoro</label>
            <InputNumber
              inputId="sharing-duration"
              value={newSettings.sharingDuration / (60 * 1000)}
              onValueChange={(e: InputNumberValueChangeEvent) => {
                if (e.value) {
                  setState({...state, newSettings: {...newSettings, sharingDuration: e.value * (60 * 1000)}})}
                }
              }
              mode="decimal"
              showButtons
              min={0}
              maxFractionDigits={2}
            />
            <label htmlFor="short-break-duration" className="block mt-4">Short Break</label>
            <InputNumber
              inputId="short-break-duration"
              value={newSettings.shortBreakDuration / (60 * 1000)}
              onValueChange={(e: InputNumberValueChangeEvent) => {
                if (e.value) {
                  setState({...state, newSettings: {...newSettings, shortBreakDuration: e.value * (60 * 1000)}})}
                }
              }
              mode="decimal"
              showButtons
              min={0}
              maxFractionDigits={2}
            />
            <label htmlFor="long-break-duration" className="block mt-4">Long Break</label>
            <InputNumber
              inputId="long-break-duration"
              value={newSettings.longBreakDuration / (60 * 1000)}
              onValueChange={(e: InputNumberValueChangeEvent) => {
                if (e.value) {
                  setState({...state, newSettings: {...newSettings, longBreakDuration: e.value * (60 * 1000)}})}
                }
              }
              mode="decimal"
              showButtons
              min={0}
              maxFractionDigits={2}
            />
            <div className="flex flex row mt-4">
              <PrimeReactButton className="p-button-secondary" onClick={() => {
                setState({...state, newSettings: DEFAULT_SETTINGS})
                toast.current.show({severity:'info', summary: 'Settings Reset', detail: 'Settings have been reset to default values.', life: 3000});
              }}>
                Reset Settings
              </PrimeReactButton>
              <PrimeReactButton className="ml-4" onClick={handleSettingsChange}>
                Done
              </PrimeReactButton>
            </div>
          </Dialog>
        }

        {
          <Dialog
            visible={isLoginVisible}
            dismissableMask
            onHide={() => setIsLoginVisible(false)}
            header='Login'
            breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '50vw'}}
          >
            <LoginForm formData={loginFormData} setFormData={setLoginFormData} setIsLoginVisible={setIsLoginVisible} setIsForgotPasswordVisible={setIsForgotPasswordVisible}/>
            <div>Do not have an account?</div>
            <div
              className="underline hover:text-gray-500 cursor-pointer"
              onClick={() => {
                setIsLoginVisible(false)
                setIsRegisterVisible(true)
              }}>
              Create account
            </div>
          </Dialog>
        }

        {
          <Dialog
            visible={isRegisterVisible}
            dismissableMask
            onHide={() => setIsRegisterVisible(false)}
            header='Register'
            breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '50vw'}}
          >
            <RegisterForm formData={registerFormData} setFormData={setRegisterFormData}/>
            <div>Already have an account?</div>
            <div className="underline hover:text-gray-500 cursor-pointer" onClick={() => {
              setIsLoginVisible(true)
              setIsRegisterVisible(false)
            }}>Login</div>
          </Dialog>
        }

        {
          <Dialog
            visible={isForgotPasswordVisible}
            dismissableMask
            onHide={() => setIsForgotPasswordVisible(false)}
            header='Reset Password'
            breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '50vw'}}
          >
            <ForgotPassword/>
            <div>Already have an account?</div>
            <div
              className="underline hover:text-gray-500 cursor-pointer"
              onClick={() => {
                setIsLoginVisible(true)
                setIsRegisterVisible(false)
                setIsForgotPasswordVisible(false)
              }}
            >
              Login
            </div>
            <div>Do not have an account?</div>
            <div
              className="underline hover:text-gray-500 cursor-pointer"
              onClick={() => {
                setIsLoginVisible(false)
                setIsRegisterVisible(true)
                setIsForgotPasswordVisible(false)
              }}>
              Create account
            </div>
          </Dialog>
        }

        <div className='text-left bg-white'>
          <div className="white mt-4">Stop procrastination by sharing your screen to the public</div>
          <div className="white mt-4">What is this?</div>
          <div className="white mt-4">Work in Public allows you to share your screen to others while you work.</div>
          <div className="white mt-4">Increase your focus</div>
          <div className="white mt-4"></div>
        </div>
        <Button variant='red' onClick={() => { console.log('state', state)}}>State</Button>
      </div>
      </GoogleOAuthProvider>
    </PrimeReactProvider>
  );
}

export default App;
